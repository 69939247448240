import { FunctionalComponent, h, Fragment } from "preact";
import { Router, Route } from "preact-router";
import { useUserAuthContext } from "../../context/UserAuthContext";

interface IProps {
  path: string;
  component: preact.FunctionalComponent<any>;
}

const Protected: FunctionalComponent<IProps> = ({ path, component }) => {
  const { isAuth } = useUserAuthContext();

  return (
    <Fragment>
      {isAuth ? (
        <Router>
          <Route path={path} component={component} />
        </Router>
      ) : null}
    </Fragment>
  );
};

export default Protected;
