import { Roles } from "./roles";

export interface ICompanyUser {
    userId: string;
	userFirstName: string;
	userLastName: string;
	userName: string;
	userEmail: string;
	companyId?: string;
	companyName?: string;
	workspaceId?: string;
	workspaceName?: string;
	workspaces?: string[];//participent in workspaces
	token?: string;//token with userId and companyId cliams
	isKorraUser?: boolean;
}

export interface IUserWorkspace {
    userId: string;
	userFirstName: string;
	userLastName: string;
	userName: string;
	userEmail: string;
	workspaceId: string;
	workspaceName: string;
	workspaces?: string[];//participent in workspaces
	token?: string;//token with userId and workspaceId cliams
	isKorraUser?: boolean;
}

export class UserWorkspace implements IUserWorkspace{

    userId: string;
	userFirstName: string;
	userLastName: string;
	userName: string;
	userEmail: string;
	workspaceId: string;
	workspaceName: string;
	workspaces?: string[];//participent in workspaces
	token?: string;//token with userId and workspaceId cliams
	isKorraUser?: boolean;
  
	constructor(data:ICompanyUser){
	  this.userId = data.userId;
	  this.userFirstName = data.userFirstName;
	  this.userLastName = data.userLastName;
	  this.userName = data.userName;
	  this.userEmail = data.userEmail;
	  this.workspaceId = data.companyId || data.workspaceId;
	  this.workspaceName = data.companyName || data.workspaceName;
	  this.workspaces = data.workspaces;
	  this.token = data.token;
	  this.isKorraUser = data.isKorraUser;
	}
  }

  export interface  UpdateAddWorkspaceFromAdminRequest{
	workspaceId: string;
	userId:string;
	addToUserId: string;
	role:Roles;
  }

  export interface  UpdateRemoveWorkspaceFromAdminRequest{
	workspaceId: string;
	userId:string;
	removeToUserId: string;
  }

  export interface  WorkspaceOnlyResponse{
	workspaceId: string;
	workspaceName: string;
  }

//   export interface IUserWorkspace {
//     userId: string;
// 	userFirstName: string;
// 	userLastName: string;
// 	userName: string;
// 	userEmail: string;
// 	workspaceId: string;
// 	workspaceName: string;
// 	workspaces?: string[];//participent in workspaces
// 	token?: string;//token with userId and workspaceId cliams
// 	isKorraUser?: boolean;
// }



