import { FunctionalComponent, h } from "preact";
import Router from "preact-router";
import Users from "../../routes/users";
import Dbx from "../../routes/dbx";
import SystemMontior from "../../routes/system_monitor";
import Protected from "./Protected";
import { Route } from "preact-router";
import Home from "../../routes/home";

const AppRouter: FunctionalComponent = () => {
  return (
    <Router>
      {/* <Route path="/users" component={Users} /> */}
      <Protected path={"/users"} component={Users} />
      <Route path={"/dbx"} component={Dbx} />
      <Protected path={"/system_monitor"} component={SystemMontior} />
      <Protected path={"/"} component={Home} />
    </Router>
  );
};

export default AppRouter;
