import { createContext, h } from "preact";
import { useContext } from "preact/hooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface IProps {
  handleMessageToaster: (message: string, flag: boolean) => void;
}
export const ToasterContextProvider = createContext<IProps>(null);

export const useUserMessageContext = () => {
  return useContext(ToasterContextProvider);
};
function ToasterContext({ children }) {
  const handleMessageToaster = (message, flag) => {
    if (flag) {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const values: IProps = {
    handleMessageToaster,
  };
  return (
    <ToasterContextProvider.Provider value={values}>
      <ToastContainer />
      {children}
    </ToasterContextProvider.Provider>
  );
}

export default ToasterContext;
