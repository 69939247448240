import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { IUserWorkspace } from "../models/user-workspace";
import { LoadingProgressStatus } from "../models/loading-progress-enum";
import { useAuth0 } from "@auth0/auth0-react";
import { getAuth0Audience } from "../configs/env";
import { getUserByEmail, getUserWorkspace } from "../api/user-apis";
import { IUser } from "../models/user";
import { useUserMessageContext } from "./ToasterContext";
import { route } from "preact-router";

export interface IGoogleAuth {
  isKorraUser: boolean; //temporary
  isAuth: boolean;
  loginToEditor: () => void;
  logOutFromEditor: () => void;
  userData: IUserWorkspace | null;
  setUserData: (user: IUserWorkspace) => void;
  loadingStatus: LoadingProgressStatus;
  setLoadingStatus: (status: LoadingProgressStatus) => void;
}

export const UserAuthContext = createContext<IGoogleAuth>(null);

export const useUserAuthContext = () => {
  return useContext(UserAuthContext);
};

/**
 * a provider that impleents the auth
   upon login/sign up, (getemail/createuser call)
 *      - client: we get from client-googleAuth a token,  (which means that this is a real google user)
 *      - server: this token is verified
 *      -          return a .secret-encrypted (JWT) token with user_id(1)
 *      - client: get-user-company with user_id(1)
 *      - server: verifies 
 *                returns new token(2) with user_id(1) and company_id
 *      - client: uses token(2) in all subsequent calls and routes
 *        
 * @param param0
 *  * @returns
 */
const UserAuthProvider: FunctionalComponent = ({ children }) => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const [isIdToken, setIsIdToken] = useState<boolean | null>(null);

  const [userData, setUserData] = useState<IUserWorkspace | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingProgressStatus>(
    LoadingProgressStatus.INIT
  );

  const [isKorraUser, setIsKorraUser] = useState<boolean>();
  const [idToken, setIdToken] = useState<string | null>(null);

  const { user, loginWithRedirect, logout, getAccessTokenSilently } =
    useAuth0();
  const { handleMessageToaster } = useUserMessageContext();
  useEffect(() => {
    const getIdToken = async () => {
      try {
        const { id_token } = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${getAuth0Audience()}/api/v2/`,
            scope: "read:users",
          },
          detailedResponse: true,
        });
        setIdToken(id_token);
        setIsIdToken(true);
      } catch (e) {
        const err = e as { error: string };
        if (err?.error && err?.error === "consent_required") {
          logOutFromEditor();
        }

        setIsIdToken(false);
      }
    };

    if (!isIdToken) {
      getIdToken();
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetch = async () => {
      await loginWithRedirect();
    };
    if (isIdToken === false) {
      fetch();
    }
  }, [isIdToken]);
  useEffect(() => {
    const getUserFromEditor = async () => {
      const email = user.email;

      let currentUser: IUser;
      let userWorkspace: IUserWorkspace;
      try {
        currentUser = await getUserByEmail(email, idToken);
        if (!currentUser) {
          logOutFromEditor("User not found: please sign up");
        }
        //we got our user id lets try to get workspaceId from userWorkspace
        userWorkspace = await getUserWorkspace(
          currentUser.id,
          currentUser.userIdToken
        );
        setIsKorraUser(userWorkspace.isKorraUser);
        if (!userWorkspace.isKorraUser) {
          logOutFromEditor("you are not korra user");
        }
        //check if companyUser collection have the company and ready
        if (
          userWorkspace?.workspaceId &&
          userWorkspace?.workspaceName
          // userWorkspace.isKorraUser
        ) {
          setUserData(userWorkspace);
          route("/");
        }
        setLoadingStatus(LoadingProgressStatus.USER_COMPANY_LOADED);
        setIsAuth(true);
      } catch (err) {
        const message = err.toString() ? err.toString() : "failed to login";
        logOutFromEditor(message);
      }
    };

    if (idToken && user) {
      getUserFromEditor();
    }
  }, [idToken, user]);
  const logOutFromEditor = (errMessage?: string) => {
    const setNullAndLogout = () => {
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/`,
        },
      });

      //setIdToken(null);
      //setIsIdToken(false);
    };
    if (errMessage) {
      // show the error and logout after 10 seconds
      handleMessageToaster(errMessage, false);
      setTimeout(() => {
        setNullAndLogout();
      }, 2000);
    } else {
      setNullAndLogout();
    }
  };

  const loginToEditor = async () => {
    return await loginWithRedirect();
  };

  const auth: IGoogleAuth = {
    isKorraUser, //temporary
    isAuth,
    loginToEditor,
    logOutFromEditor,
    userData,
    setUserData,
    loadingStatus,
    setLoadingStatus,
  };

  return (
    <UserAuthContext.Provider value={auth}>{children}</UserAuthContext.Provider>
  );
};

export default UserAuthProvider;
